/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'safe2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.563 8H5.035a3.5 3.5 0 01.662-1.596l1.08 1.08q-.142.24-.214.516m.921-1.223l-1.08-1.08A3.5 3.5 0 018 5.035v1.528q-.277.072-.516.214M9 6.563V5.035a3.5 3.5 0 011.596.662l-1.08 1.08A2 2 0 009 6.563m1.223.921l1.08-1.08c.343.458.577 1.003.662 1.596h-1.528a2 2 0 00-.214-.516M10.437 9h1.528a3.5 3.5 0 01-.662 1.596l-1.08-1.08q.142-.24.214-.516m-.921 1.223l1.08 1.08A3.5 3.5 0 019 11.965v-1.528q.277-.072.516-.214M8 10.437v1.528a3.5 3.5 0 01-1.596-.662l1.08-1.08q.24.142.516.214m-1.223-.921l-1.08 1.08A3.5 3.5 0 015.035 9h1.528q.072.277.214.516M7.5 8.5a1 1 0 112 0 1 1 0 01-2 0"/><path pid="1" d="M2.5 1A1.5 1.5 0 001 2.5V3H.5a.5.5 0 000 1H1v4H.5a.5.5 0 000 1H1v4H.5a.5.5 0 000 1H1v.5A1.5 1.5 0 002.5 16h12a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0014.5 1zm6 3a4.5 4.5 0 110 9 4.5 4.5 0 010-9"/>',
    },
});
